<template>
  <div>
    <div class="filters filters--indent">
      <div class="filters__search">
        <v-select
          @search="onSearch"
          @input="onSetOrg"
          v-model="region_org"
          :reduce="org => org.id"
          :filterable="false"
          :options="region_options"
          :get-option-label="getLabel"
          placeholder="Выберите регион"
          class="select"
        >
          <template slot="open-indicator">
            <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                 fill="none">
              <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                    stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
          </template>
          <template slot="no-options">Введите свой запрос для поиска</template>
          <template slot="option" slot-scope="option">
            <div class="select__item d-center">
              {{ option.name }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="userListTable"
      class="table-default table-default--dynamic"
    >
      <div slot="region_name" slot-scope="props" class="table-default__left">
        {{ props.row.region_name }}
      </div>
      <div slot="name" slot-scope="props" class="table-default__left">
        {{ props.row.name }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>

import {debounce} from "lodash";

export default {
  name: 'CalendarList',
  data() {
    return {
      table: {
        items: [],
        columns: ['region_name', 'name', 'date_start'],
        options: {
          headings: {
            region_name: 'Название региона',
            name: 'Название мероприятия',
            date_start: 'Дата',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 1000,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: 1000 },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
      region_options: [],
      region_org: ''
    }
  },
  computed: {
    userRole() {
      return this.$store.state.userRole
    },
  },
  mounted() {
    this.fetchItems()
  },
  methods: {
    fetchItems(region = '') {
      this.$store.dispatch('calendar/GET_EVENTS_LIST', { region })
        .then(response => {
          this.table.items = response.data
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    },
    onRowClick(row) {
      this.$router.push({ name: 'events-edit', params: { id: row.row.id } })
    },
    onSearch(search, loading) {
      loading(true);
      this.searchDelivery(loading, search, this)
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.$store.dispatch('regOrg/GET_REGION', search).then(response => {
        vm.region_options = response.data
        loading(false)
      })
    }, 350),
    getLabel(option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    onSetOrg() {
      if (this.region_org === null) this.region_org = ''
      this.fetchItems(this.region_org)
    }
  }
};
</script>
